import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import linkResolver from "../utils/linkResolver";

const AppPost = ({app, showLabel}) => {
  return (
    <>
      <Link className="blogPostLink" to={linkResolver(app)}>
        <div class="blogPost">
          <h2 class="title uppercut">{app.data.name.text}</h2>
          &nbsp;
          {showLabel && <span class="label">app</span>}
        </div>
      </Link>

      <p>{app.data.summary.text}</p>
    </>
  )
}

AppPost.propTypes = {
  post: PropTypes.shape,
  showLabel: PropTypes.bool,
}

AppPost.defaultProps = {
  post: null,
  showLabel: false,
}

export default AppPost
